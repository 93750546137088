<template>
  <div class="page">
    <div class="page-header">
      <el-button
        type="primary"
        icon="el-icon-plus"
        @click="addItem"
      >{{ $t('task.addTask') }}</el-button>
    </div>
    <div class="page-main">
      <div class="page-tools">
        <el-form :inline="true" :model="tabelSearch">
          <el-form-item v-has="'delete'">
            <el-button
              type="primary"
              icon="el-icon-plus iconfont icon-delete"
              @click="deleteItem()"
              :disabled="!tableSelection.length"
              >{{ $t('delete') }}
            </el-button>
          </el-form-item>
          <el-form-item class="page-tools-right" v-has="'list'">
            <el-button type="text" class="btn-p0" @click="filter = !filter">
              {{ $t('filter') }}
              <i :class="filter ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
            </el-button>
          </el-form-item>
          <el-form-item class="page-tools-right keywords" v-has="'list'">
            <el-input
              v-model="tabelSearch.keywords"
              :placeholder="$t('task.keywordsPla')"
              prefix-icon="el-icon-search"
              clearable
              @change="getTabelData"
            ></el-input>
          </el-form-item>
          <el-form-item class="page-tools-right" v-has="'list'">
            <dist-select
              v-model="tabelSearch.type"
              :optionData="taskTimeType"
              :selectAll="{ label: $t('task.allTaskTime'), value: '' }"
              @change="getTabelData"
            ></dist-select>
          </el-form-item>
          <el-form-item class="page-tools-right" v-has="'list'">
            <dist-select
              v-model="tabelSearch.title"
              :optionData="taskType"
              :selectAll="{ label: $t('task.allTaskType'), value: '' }"
              @change="getTabelData"
            ></dist-select>
          </el-form-item>
          <el-form-item class="page-tools-right" v-has="'list'">
            <dist-select
              v-model="tabelSearch.status"
              :optionData="taskState"
              :selectAll="{ label: $t('task.allStatus'), value: '' }"
              @change="getTabelData"
            ></dist-select>
          </el-form-item>
          <div v-show="filter" v-has="'list'">
            <el-form-item class="page-tools-right">
              <!-- <el-date-picker
                v-model="datePicker"
                type="datetimerange"
                :start-placeholder="$t('startTime')"
                :end-placeholder="$t('endTime')"
                value-format="timestamp"
                :default-time="['00:00:00', '23:59:59']"
                @change="getTabelData"
                style="width: 100%"
              ></el-date-picker> -->
              <select-time
                :startTime="tabelSearch.beginTime"
                :endTime="tabelSearch.endTime"
                @change="changeTime"
              />
            </el-form-item>
          </div>
        </el-form>
      </div>
      <table-pagination
        memory="task"
        ref="refTable"
        :height="pageTabelHeight"
        :tableData="tableData"
        :columnData="columnData"
        selectionShow
        @selection-change="selectionChange"
        optionShow
        :optionMinWidth="130"
        columnOptionShow
        :total="tabelTotal"
        :currentPage.sync="tabelSearch.currentPage"
        :pageSize.sync="tabelSearch.pageSize"
        @changeCurrentPage="getTabelData"
        @sort-change="tabelSortChange"
      >
        <!-- option -->
        <template slot="option" slot-scope="scope">
          <el-tooltip
            effect="dark"
            :content="$t('task.viewTaskDetails')"
            placement="top"
            :open-delay="1000"
            v-has="'edit'"
          >
            <el-button type="text" class="btn-p0" @click="viewItem(scope.row.id)">
              <i class="el-icon-warning-outline iconfont icon-info"></i>
            </el-button>
          </el-tooltip>
          <el-tooltip
            v-if="[0, 1].includes(scope.row.status)"
            effect="dark"
            :content="$t('edit')"
            placement="top"
            :open-delay="1000"
            v-has="'edit'"
          >
            <el-button type="text" class="btn-p0" @click="editItem(scope.row.id)">
              <i class="el-icon-edit iconfont icon-edit"></i>
            </el-button>
          </el-tooltip>
          <el-tooltip
            v-if="[5].includes(scope.row.status)"
            effect="dark"
            :content="$t('task.startTask')"
            placement="top"
            :open-delay="1000"
            v-has="'start'"
          >
            <el-button type="text" class="btn-p0" @click="stopItem(scope.row)">
              <i class="el-icon-video-play"></i>
            </el-button>
          </el-tooltip>
          <el-tooltip
            v-if="[0, 1].includes(scope.row.status)"
            effect="dark"
            :content="$t('task.pauseTask')"
            placement="top"
            :open-delay="1000"
            v-has="'stop'"
          >
            <el-button type="text" class="btn-p0" @click="stopItem(scope.row)">
              <i class="el-icon-video-pause"></i>
            </el-button>
          </el-tooltip>
          <el-tooltip
            effect="dark"
            :content="$t('delete')"
            placement="top"
            :open-delay="1000"
            v-has="'delete'"
          >
            <el-button type="text" class="btn-p0" @click="deleteItem(scope.row.id)">
              <i class="el-icon-delete"></i>
            </el-button>
          </el-tooltip>
        </template>
      </table-pagination>
    </div>
    <dailog-task-details
      v-if="taskDetailsVisible"
      :visible.sync="taskDetailsVisible"
      :req="taskDetailsReq"
    ></dailog-task-details>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { taskSearch, removeTaskBatch, taskExcute, taskPause } from '@/api/task'
import { TablePagination, DistSelect, SelectTime } from '@/components'
import DailogTaskDetails from './dailog-task-details'
import mixinTable from '@/components/mixin/table'

export default {
  name: 'Task',
  mixins: [mixinTable],
  components: {
    TablePagination,
    DistSelect,
    DailogTaskDetails,
    SelectTime
  },
  data() {
    return {
      filter: false,
      tabelSearch: {
        currentPage: 1,
        pageSize: 10,
        sortField: 'createTime',
        sortOrder: 'desc',
        keywords: '',
        status: '', // All Status
        title: '', // All Task Type
        type: '', // All Task Time
        beginTime: null,
        endTime: null,
        site: []
      },
      tabelTotal: 0,
      columnData: [
        {
          label: this.$t('task.taskName'),
          prop: 'name',
          minWidth: 200,
        },
        {
          label: this.$t('task.taskType'),
          prop: 'title',
          minWidth: 150,
          filter: (val) => this.taskType[val],
        },
        {
          label: this.$t('task.taskTime'),
          prop: 'type',
          minWidth: 260,
          filter: (val, row) => {
            return `${this.taskTimeType[val]}(${this.$dateFormat(row.beginTime)})`
          },
        },
        {
          label: this.$t('creator'),
          prop: 'createUser',
          minWidth: 150,
        },
        {
          label: this.$t('status'),
          prop: 'status',
          minWidth: 150,
          filter: (val) => this.taskState[val],
        },
        {
          label: this.$t('task.runTime'),
          prop: 'taskTime',
          minWidth: 150,
          filter: 'dateFormat',
        },
      ],
      tableData: [],
      // 查看
      taskDetailsVisible: false,
      taskDetailsReq: {},
    }
  },
  computed: {
    ...mapGetters('dist', ['taskState', 'taskTimeType', 'taskType']),
    ...mapGetters('session', ['pageTabelHeight']),
    // datePicker: {
    //   get() {
    //     if (this.tabelSearch.beginTime && this.tabelSearch.endTime) {
    //       return [this.tabelSearch.beginTime, this.tabelSearch.endTime]
    //     } else {
    //       return []
    //     }
    //   },
    //   set(val) {
    //     console.log('时间选择', val)
    //     this.tabelSearch.beginTime = val ? val[0] : ''
    //     this.tabelSearch.endTime = val ? val[1] : ''
    //   },
    // },
  },
  created() {
    if (this.$route.query.macAddress) this.tabelSearch.keywords = this.$route.query.macAddress
    this.tableInit()
  },
  methods: {
    changeTime(event) {
      this.tabelSearch.beginTime = event[0] || null
      this.tabelSearch.endTime = event[1] || null
      this.getTabelData()
    },
    // 获取数据
    getTabelData() {
      taskSearch(this.tabelSearch)
        .then((res) => {
          this.tableData = res.data.result.rows[0].list // 表格数据
          this.tabelTotal = res.data.result.rows[0].total // 表格数据条目
          this.$refs.refTable.clearFilter() // 表格过滤清空
        })
        .catch(() => {})
    },
    // 排序
    tabelSortChange({ order, prop }) {
      this.tabelSearch.sortOrder = order ? order : 'desc'
      this.tabelSearch.sortField = order ? prop : 'createTime'
      this.getTabelData()
    },
    // 增
    addItem() {
      this.$router.push('/task/addTask')
    },
    // 查看
    viewItem(id) {
      this.taskDetailsVisible = true
      this.taskDetailsReq = { id }
    },
    // 编辑
    editItem(id) {
      this.$router.push({ path: '/task/editTask', query: { id } })
    },
    // 删
    deleteItem(id) {
      this.$confirm(this.$t('task.deleteTip'), this.$t('tip'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning',
      })
        .then(() => {
          console.log(this.tableSelection)
          const ids = id ? [id] : this.tableSelection.map((e) => e.id),
            args = this.tableSelection.map((e) => e.name)
          removeTaskBatch({ ids, args })
            .then((res) => {
              this.$message.success(res.data.message)
              this.getTabelData()
            })
            .catch(() => {})
        })
        .catch(() => {})
    },
    // 暂停任务
    stopItem({ id, status }) {
      const stopApi = status == 5 ? taskExcute : taskPause
      stopApi({ id })
        .then(({ data }) => {
          this.$message.success(data.message)
          this.getTabelData()
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep.el-input--small .el-input__inner {
  $btn-height: 44px;
  height: $btn-height;
  line-height: $btn-height;
  width: 190px;
}
</style>
