<style lang="scss" scoped>
  .count {
    line-height: 105px;
  }
</style>
<template>
  <el-dialog
    :title="$t('task.taskDetails')"
    :visible.sync="visible"
    width="960px"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <el-row>
      <el-col :span="12">{{ $t('task.taskType') }}：{{ taskType[this.form.title] }}</el-col>
      <el-col :span="12">{{ $t('task.taskTime') }}：{{ taskTimeType[this.form.type] }}</el-col>
    </el-row>
    <el-row>
      <el-col :span="10">
        <div class="count">{{ $t('failed') + failed }} / {{ $t('total') + total}}</div>
      </el-col>
      <el-col :span="14">
        <el-form class="fliter" :inline="true" :model="tabelSearch">
          <el-form-item>
            <el-input
              v-model="tabelSearch.keywords"
              size="small"
              :placeholder="$t('sipServer.enterMAC')"
              prefix-icon="el-icon-search"
              clearable
              @change="getTabelData"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <dist-select
              v-model="tabelSearch.status"
              size="small"
              :optionData="taskResult"
              :selectAll="{ label: $t('device.allDeviceResult'), value: null }"
              @change="getTabelData"
            ></dist-select>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <table-pagination
      height="200px"
      :tableData="tableData"
      :columnData="columnData"
      :currentPage.sync="tabelSearch.currentPage"
      :pageSize.sync="tabelSearch.pageSize"
      :total="tabelTotal"
      @changeCurrentPage="getTabelData"
    >
      <template slot="status" slot-scope="slotData">
        {{ taskResult[slotData.row.status] + (slotData.row.status == 3 ? `, ${slotData.row.result}` : '') }}
      </template>
    </table-pagination>
    <span slot="footer">
      <el-button @click="handleClose">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="submit">{{ $t('task.runAgainTask') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { taskQuery, taskDetailList, taskCount } from '@/api/task'
import { TablePagination, DistSelect } from '@/components'

export default {
  name: 'viewTaskDetails',
  components: {
    TablePagination,
    DistSelect,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    req: Object,
  },
  data() {
    return {
      failed: 0,
      total: 0,
      form: {},
      tabelTotal: 0,
      tabelSearch: {
        currentPage: 1,
        pageSize: 10,
        sortField: 'taskTime',
        sortOrder: 'desc',
        taskId: '',
        keywords: '',
        status: null,
      },
      columnData: [
        {
          label: this.$t('device.macAddress'),
          prop: 'macAddress',
          minWidth: 150,
          filter: 'toUpperCase',
        },
        {
          label: this.$t('device.deviceName'),
          prop: 'deviceName',
          minWidth: 140,
        },
        {
          label: this.$t('device.model'),
          prop: 'modelName',
          minWidth: 100,
        },
        {
          label: this.$t('device.deviceStatus'),
          prop: 'deviceStatus',
          minWidth: 140,
          filter: (val) => this.deviceStatus[val],
        },
        {
          label: this.$t('task.runTime'),
          prop: 'taskTime',
          minWidth: 130,
          filter: 'dateFormat',
        },
        {
          label: this.$t('task.result'),
          prop: 'status',
          minWidth: 100,
          slot: true,
        },
      ],
      tableData: [],
    }
  },
  computed: {
    ...mapGetters('dist', ['taskTimeType', 'taskType', 'deviceStatus', 'taskResult']),
  },
  created() {
    this.getData(this.req.id)
  },
  methods: {
    getData(id) {
      taskQuery({ id })
        .then(({ data }) => {
          this.form = data.result.rows[0]
          this.tabelSearch.taskId = this.form.id
          this.getTabelData()
        })
        .catch(() => {})
    },
    getTabelData() {
      taskDetailList(this.tabelSearch)
        .then(({ data }) => {
          const dist = {
            'Device is offline': this.$t('DeviceIsOffline'),
            'Device is abnormal': this.$t('DeviceIsAbnormal'),
            'Device has beendisable': this.$t('DeviceHasBeenDisable'),
            'Unknown': this.$t('Unknown'),
          }
          this.tableData = data.result.rows[0].list.map(item=> {
            item.result = dist[item.result]
            return item
          })
          this.tabelTotal = data.result.rows[0].total
          taskCount(this.tabelSearch)
            .then(({ data }) => {
              let { total, failed } = data.result.rows[0]
              this.total = total
              this.failed = failed
            })
        })
        .catch(() => {})
    },
    // 取消
    handleClose() {
      this.$emit('update:visible', false)
    },
    // 确定
    submit() {
      this.$router.push({ path: '/task/runAgainTask', query: { id: this.req.id } })
    },
  },
}
</script>

<style lang="scss" scoped>
.fliter {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
</style>
